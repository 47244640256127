function setEqualHeight() {
    $(".product-grid-product .grid-element-content-header-image").matchHeight();
    $(".product-grid-product .grid-element-title").matchHeight();
    $(".product-grid-product .product-options").matchHeight();
    $(".product-grid-product .nakup").matchHeight();
    $(".product-grid-related .grid-element-content-header-image").matchHeight();
    $(".product-grid-related .grid-element-title").matchHeight();
    $(".product-grid-related .product-options").matchHeight();
    $(".product-grid-related .nakup").matchHeight();
    $(".grid-menu-item .menu-item-title").matchHeight();
    $(".grid-menu-item").matchHeight();
    $(".grid-news-item .article-title").matchHeight();
    $(".grid-news-item .article-content").matchHeight();
    $(".grid-news-item .article-container").matchHeight();
    $(".grid-news-item-026-002").matchHeight();
    $("#footer-wrapper .footer-info").matchHeight();
    $("#footer-wrapper .footer-enako").matchHeight();
    $(".enaka-visina-1").matchHeight();
    $(".enaka-visina-2").matchHeight();
    $(".enaka-visina-3").matchHeight();
    $(".navijaski-komplet-video").matchHeight();
    $(".how-to .how-to-text").matchHeight();
    $(".how-to .how-to-product").matchHeight();
}
$(window).on("load", function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    setEqualHeight();
    // $.fn.matchHeight._update();
});

$('#main-menu-state').on("change", function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $menu.css("max-height", maxHeight + "px");
                if ($menu.outerHeight() > maxHeight) {
                    $menu.css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $menu.css("max-height", "");
            $menu.css("height", "");
        });
    }
});

function afterHowToVideo1() {
    var current_breakpoint_num = getNumSkelSize();
    if (current_breakpoint_num > 2) {
        $(".how-to-1 .how-to-text, .how-to-1 .mejs__container").matchHeight();
    }
}

function afterHowToVideo2() {
    var current_breakpoint_num = getNumSkelSize();
    if (current_breakpoint_num > 2) {
        $(".how-to-2 .how-to-text, .how-to-2 .mejs__container").matchHeight();
    }
}
